<template>
  <component :is="clientData === null ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="clientData === null && !loading"
    >
      <h4 class="alert-heading">
        Erro ao recuperar os dados
      </h4>
      <div class="alert-body">
        Não encontramos os dados deste usuário. Voltar para
        <b-link
          class="alert-link"
          :to="{ name: 'clients-list'}"
        >
          a lista de usuários.
        </b-link>
      </div>
    </b-alert>

    <!-- User Info: Input Fields -->
    <b-form
      v-if="clientData && !loading"
    >
      <b-row class="mb-1">
        <b-col>
          <h4>{{ clientData.profile.nome ? clientData.profile.nome : 'Nome do Professor' }}</h4>
        </b-col>
        <b-col>
          <b-button
            class="float-right"
            variant="success"
            @click="updateClient"
          >
            Gravar
          </b-button>
        </b-col>
      </b-row>

      <b-row>

        <b-col>
          <b-row>

            <b-col
              cols="12"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Nome"
                    label-for="name"
                  >
                    <b-form-input
                      id="name"
                      v-model="clientData.profile.nome"
                      :state="validation.profile.nome.state"
                    />
                    <b-form-invalid-feedback>
                      {{ validation.profile.nome.feedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Email"
                    label-for="email"
                  >
                    <b-form-input
                      id="email"
                      v-model="clientData.email"
                      type="email"
                      :state="validation.email.state"
                    />
                    <b-form-invalid-feedback>
                      {{ validation.email.feedback }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="Telefone"
                    label-for="phone"
                  >
                    <b-form-input
                      id="phone"
                      v-model="clientData.profile.phone"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="RG"
                    label-for="rg"
                  >
                    <b-form-input
                      id="rg"
                      v-model="clientData.profile.rg"
                    />
                  </b-form-group>
                </b-col>

                <b-col
                  cols="12"
                  md="6"
                >
                  <b-form-group
                    label="CREF"
                    label-for="cref"
                  >
                    <b-form-input
                      id="cref"
                      v-model="clientData.profile.cref"
                    />
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>

          </b-row>
        </b-col>

      </b-row>

      <b-row class="mt-1">
        <b-col>
          <h6>Endereço</h6>
        </b-col>
      </b-row>
      <b-row>

        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            label="Logradouro"
            label-for="address"
          >
            <b-form-input
              id="address"
              v-model="clientData.profile.address"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Número"
            label-for="address_number"
          >
            <b-form-input
              id="address_number"
              v-model="clientData.profile.address_number"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-group
            label="Complemento"
            label-for="address_complement"
          >
            <b-form-input
              id="address_complement"
              v-model="clientData.profile.address_complement"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="CEP"
            label-for="zip_code"
          >
            <b-form-input
              id="zip_code"
              v-model="clientData.profile.zip_code"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="8"
        >
          <b-form-group
            label="Cidade"
            label-for="city"
          >
            <b-form-input
              id="city"
              v-model="clientData.profile.city"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="2"
        >
          <b-form-group
            label="UF"
            label-for="state"
          >
            <b-form-input
              id="state"
              v-model="clientData.profile.state"
            />
          </b-form-group>
        </b-col>

      </b-row>
    </b-form>

  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink, BFormGroup, BFormInput, BButton,
  BRow, BCol, BForm, BAvatar, BFormTextarea, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BForm,
    BAvatar,
    BFormTextarea,
    BFormInvalidFeedback,

    vSelect,
  },
  props: {
    userId: {
      type: Number,
      required: false,
      default: -1,
    },
  },
  data() {
    return {
      validation: {
        email: {
          value: null,
          feedback: '',
        },
        profile: {
          nome: {
            value: null,
            feedback: '',
          },
        },
      },
      validationBase: {
        email: {
          value: null,
          feedback: '',
        },
        profile: {
          nome: {
            value: null,
            feedback: '',
          },
        },
      },
      clientData: null,
      loading: true,
      emptyProfile: {
        phone: '',
        nome: '',
        cref: '',
        rg: '',
        zip_code: '',
        address: '',
        address_number: '',
        address_complement: '',
        city: '',
        state: '',
      },
      emptyClient: {
        first_name: '',
        last_name: '',
        email: '',
      },
    }
  },
  created() {
    if (this.userId !== -1) {
      this.$store.dispatch('professors/getClientById', this.userId).then(() => {
        this.clientData = this.$store.state.professors.clientData
        if (this.clientData.profile === null) {
          this.clientData.profile = { ...this.emptyProfile }
        }
        this.loading = false
        this.refInputEl = ref(null)
        this.previewEl = ref(null)
        this.inputImageRenderer = useInputImageRenderer(this.refInputEl, base64 => {
          // eslint-disable-next-line no-param-reassign
          this.clientData.profile.logo = base64
        })
      })
    } else {
      this.clientData = this.emptyClient
      this.clientData.profile = { ...this.emptyProfile }
      this.loading = false
      this.refInputEl = ref(null)
      this.previewEl = ref(null)
      this.inputImageRenderer = useInputImageRenderer(this.refInputEl, base64 => {
        // eslint-disable-next-line no-param-reassign
        this.clientData.profile.logo = base64
      })
    }
  },
  methods: {
    updateClient() {
      this.$store.dispatch('professors/updateClient', this.clientData)
        .then(response => {
          this.validation = JSON.parse(JSON.stringify(this.validationBase))
          this.clientData.id = response.data.id
          this.updated = false
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Professor',
              icon: 'UserCheckIcon',
              variant: 'success',
              text: response.status === 200 ? 'Professor atualizado' : 'Professor criado',
            },
          })
          this.$router.push({ name: 'professors-list' })
        })
        .catch(error => {
          this.validation = JSON.parse(JSON.stringify(this.validationBase))
          /* eslint-disable */
          for (const [k, v] of Object.entries(error.response.data)) {
            if (Array.isArray(v)) {
              this.validation[k] = {
                state: false,
                feedback: v[0],
              }
            } else {
              for (const [k2, v2] of Object.entries(v)) {
                this.validation[k][k2] = {
                  state: false,
                  feedback: v2[0],
                }
              }
            }
          }
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Professor',
              icon: 'UserXIcon',
              variant: 'danger',
              text: 'Não conseguimos gravar esses dados. Por favor verificar.',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
